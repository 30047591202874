<template>
  <div v-if="!error" style="width: 100%;margin-bottom: 20px; margin-top: 10px">
    <div class="mt-4">
    <p style="font-size: 17px; line-height: 5px;">{{ $t('Teller.captcha_label') }}</p>
    <p style="font-size: 14px; line-height: 5px; text-decoration-line: underline; cursor: pointer;" @click="generateCaptcha">{{ $t('Teller.captcha_refresh') }}</p>
  <div v-if="!loading" @click="focusInput" style="width: 100%;height: 80px;display: flex;justify-content: center;padding: 10px">
    <div style="margin: auto;height: 100%;display: flex">
      <img @click="focusInput" :src="captchaImage" style="margin: 10px 0;">
      <input required @input="$emit('captchaValue', captchaValue)" type="text"
                size="large"
                class="input email"
                id="captchaInput" v-model="captchaValue" style="margin-left: 10px;font-size: 15px;text-align: center !important; width: 100%; margin-top: 10px; margin-bottom: 10px;"/>
    </div>
  </div>
  <div style="display: flex;justify-content: center" v-else>
    <div class="spinner-border text-primary" style="margin: auto" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import {userService} from "@/_services";

export default {
  name: "Captcha",
  data() {
    return {
      captchaValue: '',
      captchaLoading: true,
      captchaImage: null,
      loading: false,
      error: false
    }
  },
  props: {
    errorMessage: String,
    regenerate: Boolean,
  },
  watch: {
    regenerate()  {
      this.generateCaptcha();
    }
  },
  emits: ['captchaAcquired', 'captchaValue'],
  methods: {
    captchaValueChanged() {
      this.$emit('captchaValue', this.captchaValue);
    },
    focusInput() {
      document.getElementById('captchaInput').focus();
    },
    generateCaptcha() {
      this.loading = true;
      this.captchaValue = '';
      userService.getCaptcha().then((data)=> {
        this.captchaImage = data.img;
        this.$emit('captchaAcquired', data.key);
        document.getElementById("captchaInput").focus();
      })
      .catch(() => {
        // this.error = true;
      })
      .finally(() => {
        this.loading = false;
      })
    },
  },
  created() {
  this.generateCaptcha();
  }
}
</script>